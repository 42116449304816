import React from 'react'
import Layout from '../components/layout'
import PageTitle from '../components/page-title'
import Heading from '../components/heading'
import SEO from '../components/seo'

export default function Access() {
  const title = {
    jp: 'アクセス',
    en: 'ACCESS'
  }

  const heading = 'アクセスマップ'

  const items = [
    {
      id: 'access-01',
      label: '社名',
      contents: '株式会社 サンリク'
    },
    {
      id: 'access-02',
      label: '所在地',
      contents: '東京都調布市柴崎2丁目2番18号'
    },
    {
      id: 'access-03',
      label: '電話番号',
      contents: '042-444-5796'
    }
  ]

  const accessRoutes = [
    {
      id: 'access-04',
      label: '車でお越しの方',
      routes: [
        {
          id: 'route-01',
          contents: '中央自動車道「調布」ICから10分',
          url: 'https://goo.gl/maps/EnrqrVnLRoR2'
        }
      ]
    },
    {
      id: 'access-05',
      label: '電車でお越しの方',
      routes: [
        {
          id: 'route-02',
          contents: '京王線「柴崎」駅から徒歩10分',
          url: 'https://goo.gl/maps/EnrqrVnLRoR2'
        },
        {
          id: 'route-03',
          contents: '京王線「つつじヶ丘」駅から徒歩13分',
          url: 'https://goo.gl/maps/cit8nf5WdNr'
        },
      ]
    },
  ]

  return (
    <Layout>
      <SEO title="アクセス | 株式会社サンリク" />
      <PageTitle titleJP={title.jp} titleEN={title.en} />
      <div className="max-w-screen-lg mx-auto mt-20 px-6 md:px-0">
        <Heading heading={heading} />

        <div className="relative w-full h-0 pt-map mt-10">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3241.7083631062037!2d139.56285151525825!3d35.659556280199375!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018f04a6d6f81d9%3A0x96447b68c24c1bc7!2z44CSMTgyLTAwMTQg5p2x5Lqs6YO96Kq_5biD5biC5p-05bSO77yS5LiB55uu77yS4oiS77yR77yY!5e0!3m2!1sja!2sjp!4v1632177609957!5m2!1sja!2sjp"
            style={{ border: 0 }}
            allowFullScreen loading="lazy"
            className="absolute top-0 left-0 w-full h-full"
            title="株式会社サンリクへのアクセス"
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 mt-4 md:mt-10">
          <div>
            {items.map(item => (
              <div key={item.id} className="mt-6">
                <h3 className="font-bold">{item.label}</h3>
                <p className="mt-2">{item.contents}</p>
              </div>
            ))}
          </div>

          <div>
            {accessRoutes.map(accessRoute => (
              <div key={accessRoute.id} className="mt-6">
                <h3 className="font-bold">{accessRoute.label}</h3>
                {accessRoute.routes.map(route => (
                  <div key={route.id} className="mt-2">
                    <a
                      href={route.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="underline"
                    >
                      {route.contents}
                    </a>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}
